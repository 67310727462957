import * as React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"

import { getPreferredLang } from "../functions/getPreferredLang"
import {
  findSecondaryLang,
  getSecondaryLangs,
  getStoredLocale,
  isDefaultStored,
  isSecondaryStored,
  storeLocale,
} from "../functions/langUtils"

import { isSSR } from "../functions/isSSR"
import { useLocales } from "../hooks/useLocales"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsNotFoundPage {
        nodes {
          seo {
            seoTitle: title
          }
          locale
          title
          subtitle
          backToHomeText
        }
      }
    }
  `)
  const { defaultLocale, locales } = useLocales()

  const appLangCodes = [...locales]
  const storedLocale = getStoredLocale()

  if (!isSSR) {
    const browserLangCodes = navigator.languages
    const {
      allDatoCmsNotFoundPage: { nodes: propNodes },
    } = data

    const [defaultLangPropsNode] = propNodes

    // Default locale data

    const {
      seo: { seoTitle },
      title,
      subtitle,
      backToHomeText,
    } = defaultLangPropsNode

    const defaultLangProps = {
      headProps: {
        title: seoTitle,
        locale: defaultLocale,
      },
      heroProps: {
        title,
        subtitle,
      },
      linkProps: {
        children: backToHomeText,
        to: "/",
      },
    }

    const getProps = () => {
      // If default locale is already stored

      const isDefaultLangStored = isDefaultStored(
        appLangCodes,
        storedLocale,
        defaultLocale
      )

      if (isDefaultLangStored) return defaultLangProps

      // If secondary locale is already stored

      const isSecondaryLangStored = isSecondaryStored(
        appLangCodes,
        storedLocale,
        defaultLocale
      )

      if (isSecondaryLangStored) {
        const storedLangProps = propNodes.find(
          ({ locale }) => locale === storedLocale
        )

        return {
          headProps: {
            title: storedLangProps.seo.seoTitle,
            locale: storedLocale,
          },
          heroProps: {
            title: storedLangProps.title,
            subtitle: storedLangProps.subtitle,
          },
          linkProps: {
            children: storedLangProps.backToHomeText,
            to: `/${storedLocale}`,
          },
        }
      }

      /**
       * If no locale has been stored previously (e.g. first time visit), evaluate
       * the preferred locale according to browser language list
       */

      const matchingLangCode = getPreferredLang(browserLangCodes, appLangCodes)

      const defaultLocaleMatch = matchingLangCode === defaultLocale

      // If it equals to default language

      if (!storedLocale && defaultLocaleMatch) {
        storeLocale(defaultLocale)
        return defaultLangProps
      }

      // If it equals to secondary langauge

      const secondaryLanguages = getSecondaryLangs(appLangCodes)
      const secondaryLanguageMatch = findSecondaryLang(
        secondaryLanguages,
        matchingLangCode
      )
      if (!storedLocale && secondaryLanguageMatch) {
        storeLocale(secondaryLanguageMatch)
        const secondaryLangProps = propNodes.find(
          ({ locale }) => locale === secondaryLanguageMatch
        )
        return {
          headProps: {
            title: secondaryLangProps.seo.seotitle,
            locale: secondaryLanguageMatch,
          },
          heroProps: {
            title: secondaryLangProps.title,
            subtitle: secondaryLangProps.subtitle,
          },
          linkProps: {
            children: secondaryLangProps.backToHomeText,
            to: `/${secondaryLanguageMatch}`,
          },
        }
      }

      // Else return default locale data

      return defaultLangProps
    }

    const { headProps, heroProps, linkProps } = getProps()

    return (
      <div className="v-screen fixed inset-0 flex h-screen flex-col items-center justify-center gap-4 bg-primary-600">
        <h1 className="text-6xl font-bold text-white ">{heroProps.title}</h1>
        <p className="text-xl text-white ">{heroProps.subtitle}</p>
        <Link
          className="cursor-pointer rounded bg-white px-5 py-2.5 text-xl font-semibold text-primary-600 shadow-lg"
          to={linkProps.to}
        >
          {linkProps.children}
        </Link>
      </div>
    )
  }
  return null
}

export default NotFoundPage
